<template>
  <div>
    {{questionnaire_info}}
  </div>
</template>

<script>
/**
 * { "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IkZGMjVGMjY4LTM0OTUtNDY1MS05Mzc1LTA4M0NERTZBMjI5RSIsIm5hbWUiOiLpgrEifQ.7-7Q6smiAk1jnfbhUuwVeyGYFkd3amakm1Kqykimh8Q",
 * "patient_id": "2260",
 * "created_by": "FF25F268-3495-4651-9375-083CDE6A229E",
 * "template_id": "17",
 * "template_link": "https://questionnaire.yunicu.com/#/test",
 * "isSelect": "false",
 * "type": "see",
 * "id": "2392" }
 */
export default {
  name: "Home.vue",
  data(){
    return{
      questionnaire_info:'',
    }
  },
  mounted() {
    const questionnaire_info = this.$route.query
    this.questionnaire_info = questionnaire_info;
  }
}
</script>

<style scoped>

</style>